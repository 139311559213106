import "./datePicker.css";
import VisuallyHidden from "@reach/visually-hidden";
import { Box, Flex } from "@rebass/grid";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";

import ButtonArrow from "components/icons/ButtonArrow";
import { Column, Row } from "components/layout";
import { PoseableBox, PoseableText } from "components/poseable";
import { formHeading, normal } from "components/typography/sizes";

import * as easings from "d3-ease";
import JSZip from "jszip";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import posed from "react-pose";

import { Button, Text } from "rebass";
import styled, { withTheme } from "styled-components";
import events from "utils/events";
import GlobalEmitter from "utils/GlobalEmitter";

const PosedForm = posed(PoseableBox)({
  normal: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinIn,
    },
  },
  disabled: {
    opacity: 0.1,
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
  sending: {
    opacity: 0.5,
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
  submitted: {
    opacity: 0.25,
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
});

const inputCss = `
position: relative;
display: block;
color: white;
border-radius: 0;
height: 50px;
width: 100%;
background: rgba(216, 216, 216, 0.5);
border: 1px solid #979797;
line-height: 1em;
padding: 12px 20px;
font-family: ${({ theme }) => theme.fonts.body};
will-change: border-color;
transition: border-color 0.2s ease-in;
::placeholder {
  color: white;
  opacity: 0.5;
}

:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: border-color 0.2s ease-out;
}
:focus {
  outline: none;
  border: 1px solid #fff;
  transition: border-color 0.2s ease-out;
}

&.error {
  border-color: #f48572;
  transition: border-color 0.2s ease-out;
  color: #f48572;
  ::placeholder {
    color: #f48572;
    opacity: 1;
  }
}
`;

const Input = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.small};
  ${inputCss}
`;

const StyledFileUpload = styled(
  posed(PoseableText)({ hoverable: true, focusable: true })
)`
  position: relative;
  display: inline-block;
  margin-top: 5px;
  align-items: center;

  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    cursor: pointer;
  }

  & input + label {
    font-size: inherit;
    display: inline-block;
    cursor: pointer;
  }

  & svg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    :nth-child(2) {
      top: 0.5em;
      right: 0.5em;
    }
  }

  & span {
    position: absolute;
    opacity: 0.5;
    left: 0;
    top: 100%;
  }
`;

function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateElement(element, errors, doAlerts = false) {
  switch (element.getAttribute(`name`)) {
    case `file`:
      // console.log(element, element.getAttribute("required"));
      if (
        element.getAttribute("required") !== null &&
        element.getAttribute("required") !== "null" &&
        element.getAttribute("required") !== "false" &&
        element.getAttribute("required") !== false
      ) {
        if (!element.files) {
          // This is VERY unlikely, browser support is near-universal
          console.error(
            "This browser doesn't seem to support the `files` property of file inputs."
          );
        } else if (doAlerts && !element.files[0]) {
          alert("No files provided.");
          errors.push(element);
        } else {
          const file = element.files[0];
          if (doAlerts && file.size > 5000000) {
            alert("file size is more than 5mb");
            errors.push(element);
          }
        }
      }
      break;
    case `number_of_creative_versions`:
      if (parseInt(element.value) < 1) {
        errors.push(element);
      }
      break;
    default:
      if (element?.value?.trim() === ``) {
        errors.push(element);
      }
      break;
  }
}

async function getFormValues(elements) {
  let result = {};
  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    if (element.getAttribute(`type`) === `file`) {
      // result[element.getAttribute(`name`)] = element.files[0];
      const zip = new JSZip();
      let files = element.files;
      // console.log({ files });
      let zippedFiles = null;
      if (files && files.length > 1) {
        files = Array.from(files);
        files.forEach((file) => zip.file(file.name, file));
        zippedFiles = await zip.generateAsync({ type: "blob" }).then((blob) => {
          const zippedFiles = new File([blob], "files.zip", {
            lastModified: Date.now(),
            type: "application/zip",
          });
          return zippedFiles;
        });
        result[element.getAttribute(`name`)] = zippedFiles;
        // console.log({ zippedFiles });
      } else if (files && files.length === 1) {
        result[element.getAttribute(`name`)] = files[0];
      }
    } else if (element.getAttribute(`type`) === `checkbox`) {
      // console.log({ element, checked: element.checked, value: element.value });
      if (!element.checked) {
        continue;
      }
      if (result[element.getAttribute(`name`)]) {
        result[element.getAttribute(`name`)] = `${
          result[element.getAttribute(`name`)]
        }, ${element.value}`;
      } else {
        result[element.getAttribute(`name`)] = element.value;
      }
    } else {
      result[element.getAttribute(`name`)] = element.value;
    }
  }
  return result;
}

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

const projectSelectOpts = [
  { value: "new", label: "New" },
  { value: "refresh", label: "Refresh" },
  { value: "revise", label: "Revise" },
  { value: "mms", label: "MMS" },
];

const projectSelectInfo = {
  new: `<p><strong>New:</strong> New template. Projects that require net-new thinking, design and messaging. Select this type if you are not updating an existing email / MMS. Deliverables include new strategy, design, template and copy, delivered through two (2) rounds of creative revisions. New projects identified here will be followed up with a kick-off briefing going into more detail than what is included in the intake form. These will be coordinated on a case-by-case basis. </p><p><strong>Timing: 5 weeks</strong></p>`,
  refresh: `<p><strong>Refresh:</strong>  Using an existing template & module. Rewriting body copy, subject line, headline, new colours & image, two (2) rounds of creative revisions, strategy check in to ensure creative still delivers on original strategy.</p><p><strong>Timing: 3 weeks</strong></p>`,
  revise: `<p><strong>Revise:</strong> Using an existing template & module. Copy changes only. Tweaking body copy (1-2 lines) OR headline, subhead and subject line. No strategy or layout updates. Two (2) rounds of creative revisions. </p><p><strong>Timing: 1.5 weeks</strong></p>`,
  mms: `<p><strong>MMS (all types):</strong> New or updated copy and/or imagery and colours. One (1) round of creative revisions. No strategy updates.</p><p><strong>Timing: 1.5 weeks</strong></p>`,
  noteRefreshRevise: `<p><strong>Note:</strong> If you’ve chosen REFRESH or REVISE types, you must include the following (if available) in addition to completing this form: 
:<ul><li>Marked up layout/copy deck changes</li><li>Past creative reference</li><li>image inspiration</li></ul></p>
  `,
  noteMms: `<p><strong>Note:</strong> If you’ve chosen MMS type, please allow for <ul><li>11 days without OEM review</li><li>13 - 15 days with OEM review</li></ul>`,
};

const deploymentInfo = `<p><strong>Note:</strong> CJ Account team to provide full WBS upon review of intake form or following the briefing meeting.</p>`;

//Email / MMS / SMS/ Other
const commsChannelTypes = [
  { value: "email", label: "Email" },
  { value: "mms", label: "MMS" },
  { value: "sms", label: "SMS" },
  { value: "other", label: "Other (please specify)" },
];

const brandTypes = [
  { value: "koodo", label: "Koodo Only" },
  { value: "public", label: "Public Only" },
  { value: "koodo_telus", label: "Koodo / TELUS" },
  { value: "koodo_public", label: "Koodo / Public" },
  { value: "public_telus", label: "Public / TELUS" },
  { value: "koodo_public_telus", label: "Koodo / TELUS / Public" },
  { value: "other", label: "Other (please specify)" },
];

const budgetTypes = [
  { value: "koodo_base_management", label: "Koodo Base Management" },
  { value: "public_base_management", label: "Public Base Management" },
  { value: "other", label: "Other (please specify)" },
];

const languages = [
  { value: "en", label: "English" },
  { value: "fr", label: "French" },
];

const Label = (props) => {
  return <Text as={`label`} style={{ display: `block` }} {...props} />;
};

const LabelSpan = (props) => {
  return (
    <Text
      as={`span`}
      color={`white`}
      fontSize={formHeading}
      fontFamily={`Larish`}
      fontWeight={`bold`}
      style={{ display: `block` }}
      mb={5}
      {...props}
    />
  );
};

const CheckBox = ({ width = [1 / 3], label, ...rest }) => {
  return (
    <Flex width={width} alignItems={`center`} mb={5}>
      <input
        type={`checkbox`}
        fontSize={normal}
        style={{ marginRight: 10 }}
        {...rest}
        req
      />
      <LabelSpan mb={0}>{label}</LabelSpan>
    </Flex>
  );
};

const Textarea = (props) => {
  return (
    <Input
      as={`textarea`}
      rows={8}
      color={`black`}
      css={`
        color: black;
        height: auto;
        min-height: 50px;
        resize: vertical;
      `}
      fontSize={normal}
      {...props}
    />
  );
};

const InfoText = ({ theme, ...rest }) => {
  return (
    <Text
      as={`div`}
      color={`white`}
      py={10}
      fontFamily={`Calibre`}
      fontSize={[16, 18, 20]}
      lineHeight={[`24px`, `28px`, `30px`]}
      css={`
        & p:not(:last-child) {
          margin-bottom: 20px;
        }

        & strong {
          color: ${theme.colors.red};
        }

        & ul {
          list-style: disc inside;
        }
      `}
      {...rest}
    />
  );
};

function IntakeForm({
  theme,
  introNode,
  sending,
  setSending,
  setThanks,
  pose,
  enabled = false,
}) {
  const formRef = useRef();
  const fileUploadRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fileValue, setFileValue] = useState(null);
  const [showCommsChannelOther, setShowCommsChannelOther] = useState(false);
  const [showBrandOther, setShowBrandOther] = useState(false);
  const [showBudgetOther, setShowBudgetOther] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [filesDueDate, setFilesDueDate] = useState(null);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState([]);
  const [projectType, setProjectType] = useState(`new`);

  const validate = (e, doSetErrors = false, doAlerts = false) => {
    setErrors([]);
    setValid(false);
    if (e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.target.classList.remove("error");
    }

    const elements = Array.from(
      formRef.current.querySelectorAll(`input, textarea, select`)
    ).filter(
      (el) => el.type !== "submit" && el.getAttribute("name") !== `bot-field`
    );

    let validationErrors = [];

    elements.forEach((element) => {
      resetError({ target: element });
      if (
        element.id !== `comms_channel_other` &&
        element.id !== `brand_other` &&
        element.id !== `budget_support_other`
      ) {
        validateElement(element, validationErrors, isSubmitted);
      }
    });

    const commsOtherElement = formRef.current.querySelector(
      "#comms_channel_other"
    );
    if (showCommsChannelOther && !commsOtherElement?.value) {
      validationErrors.push(commsOtherElement);
    }

    const brandOtherElement = formRef.current.querySelector("#brand_other");
    if (showBrandOther && !brandOtherElement?.value) {
      validationErrors.push(brandOtherElement);
    }

    const budgetOtherElement = formRef.current.querySelector(
      "#budget_support_other"
    );
    if (showBudgetOther && !budgetOtherElement?.value) {
      validationErrors.push(budgetOtherElement);
    }

    if (projectType !== `new` && !fileValue) {
      validationErrors.push(formRef.current.querySelector("#file"));
    }

    if (validationErrors.length === 0) {
      setValid(true);
    } else {
      setErrors(validationErrors);
      if (isSubmitted) {
        alert(
          "There were errors submitting your form. Please check your fields and try again."
        );
      }
    }
    ///
    return false;
  };

  const resetError = (e) => {
    e.target.classList.remove("error");
  };

  const uploadChange = (e) => {
    let fileName = "";
    const input = e.target;
    let fileNameStr;
    if (input.files && input.files.length > 1) {
      fileNameStr = `${input.files.length} files will be uploaded.`;
    } else {
      fileNameStr = `${input.value.split("\\").pop()}`;
    }
    fileName = fileNameStr !== "" ? `(${fileNameStr})` : null;
    setFileValue(fileName);
    validate({ target: formRef.current.querySelector(`#file`) });
  };

  const cancelFileUpload = (e) => {
    fileUploadRef.current.value = null;
    setFileValue(null);
    validate({ target: formRef.current.querySelector(`#file`) });
  };

  useEffect(() => {
    errors.forEach((el) => {
      if (!el.classList.contains("error")) {
        el.classList.add(`error`);
      }
    });
  }, [errors]);

  useEffect(() => {
    if (sending || !isSubmitted) {
      return;
    }
    if (!valid) {
      setIsSubmitted(false);
      try {
        GlobalEmitter.emit(events.scrollIntoView, { el: errors[0] });
        // window.scroll({
        //   top: errors[0].getBoundingClientRect().top + window.pageYOffset,
        //   left: 0,
        // });
      } catch (e) {
        // console.log("error", e);
      }
      return;
    }
    const elements = Array.from(formRef.current.elements).filter(
      (el) => el.type !== "submit"
    );

    async function getAndSendFormValues(elements) {
      const values = await getFormValues(elements);
      // return;
      setSending(true);
      fetch(`/`, {
        method: "POST",
        body: encode({
          "form-name": formRef.current.getAttribute("name"),
          ...values,
        }),
      })
        .then((r) => {
          if (r && r.ok === true) {
            setTimeout(() => {
              setThanks(true);
            }, 2000);
          } else {
            alert(
              "Something went wrong submitting your form. Please check your fields and try again."
            );
            setIsSubmitted(false);
          }
        })
        .catch((error) => {
          alert(error);
          setIsSubmitted(false);
        });
    }

    getAndSendFormValues(elements);
  }, [isSubmitted, setSending, setThanks, valid, errors]);

  // console.log({ valid, errors });

  React.useEffect(() => {
    setTimeout(() => {
      validate(null, false, false);
    }, 1);
  }, [projectType, fileValue, showCommsChannelOther]);

  return (
    <PosedForm
      ref={formRef}
      id={"koodo-basecomms-intake"}
      name={`koodo base comms intake`}
      as={`form`}
      width={1}
      onSubmit={(e) => {
        e.preventDefault();
        // console.log("submitting form...");
        setIsSubmitted(true);
        return false;
      }}
      method="POST"
      netlify
      data-netlify="true"
      netlify-honeypot="bot-field"
      data-netlify-honeypot="bot-field"
      action={`/`}
      pose={
        enabled === false
          ? `disabled`
          : sending
          ? `sending`
          : isSubmitted
          ? `submitted`
          : `normal`
      }
      disabled={enabled === false}
    >
      <fieldset
        disabled={enabled === false ? `disabled` : null}
        css={`
          border: none;

          &:focus {
            outline: none;
          }
        `}
      >
        <input type="hidden" name="form-name" value="koodo base comms intake" />
        <VisuallyHidden>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </VisuallyHidden>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1, `50%`]}>
            <Label for="submitted_by">
              <LabelSpan>Submitted by</LabelSpan>
              <Input
                id={"submitted_by"}
                name={`submitted_by`}
                as={`input`}
                type={`email`}
                placeholder={`Enter your email address`}
                fontSize={normal}
                onFocus={resetError}
                req
                onBlur={validate}
              />
            </Label>
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1, `50%`]}>
            <Label for="project_name">
              <LabelSpan>Project name</LabelSpan>
              <Input
                id={"project_name"}
                name={`project_name`}
                as={`input`}
                type={`text`}
                placeholder={`Enter project name`}
                fontSize={normal}
                onFocus={resetError}
                req
                onBlur={validate}
              />
            </Label>
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1, `50%`]}>
            <Label for="koodo_prime">
              <LabelSpan>Koodo/Public/Telus Prime</LabelSpan>
              <Input
                id={"koodo_prime"}
                name={`koodo_prime`}
                as={`input`}
                type={`text`}
                placeholder={`Enter Koodo/Public/Telus Prime`}
                fontSize={normal}
                onFocus={resetError}
                req
                onBlur={validate}
              />
            </Label>
          </Column>
          <Column width={[1, 1, `50%`]}>
            <Label for="koodo_prime_email">
              <LabelSpan>Koodo/Public/Telus Prime Email Address</LabelSpan>
              <Input
                id={"koodo_prime_email"}
                name={`koodo_prime_email`}
                as={`input`}
                type={`text`}
                placeholder={`Enter Koodo/Public/Telus prime email address `}
                fontSize={normal}
                onFocus={resetError}
                req
                onBlur={validate}
              />
            </Label>
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1, 1 / 2]}>
            <Label for="deployment">
              <LabelSpan>Deployment Date</LabelSpan>
            </Label>
            <DatePicker
              id="deployment"
              name="deployment"
              placeholderText="Enter deployment date"
              autoComplete="off"
              required
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setTimeout(() => {
                  resetError({ target: document.getElementById("deployment") });
                  validate({
                    preventDefault: () => {},
                    target: document.getElementById("deployment"),
                  });
                }, 1);
              }}
              css={`
                ${inputCss}
              `}
            />
            <InfoText
              theme={theme}
              dangerouslySetInnerHTML={{ __html: deploymentInfo }}
            />
          </Column>
          <Column width={[1, 1, 1 / 2]}>
            <Label for="budget_support" mb={[5, 10]}>
              <LabelSpan>Budget Support</LabelSpan>
            </Label>
            <Input
              as={`select`}
              id={`budget_support`}
              name={`budget_support`}
              fontSize={normal}
              onFocus={resetError}
              onChange={(e) => {
                setShowBudgetOther(e.target.value === `other`);
              }}
              style={{ paddingRight: 10 }}
              req
            >
              {budgetTypes.map(({ label, value }) => (
                <option
                  key={`budget_support-${value}`}
                  value={value}
                  style={{ color: `black`, height: 30 }}
                >
                  {label}
                </option>
              ))}
            </Input>
            <>
              <Label for="budget_support_other" mt={[5, 10]}>
                <VisuallyHidden>
                  <LabelSpan>Other Budget Support</LabelSpan>
                </VisuallyHidden>
              </Label>
              <Textarea
                id={"budget_support_other"}
                name={`budget_support_other`}
                placeholder={`Enter other budget support`}
                onFocus={resetError}
                onBlur={validate}
                req={showBudgetOther}
                rows={1}
                style={{
                  minHeight: 0,
                  height: showBudgetOther ? `auto` : 0,
                  visibility: showBudgetOther ? `visible` : `hidden`,
                }}
              />
            </>
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1, 1 / 2]}>
            <Label for="files_due">
              <LabelSpan>Final Files due to Koodo/Public/Telus team</LabelSpan>
              <DatePicker
                startDate={null}
                autoComplete="off"
                id={"files_due"}
                name={`files_due`}
                placeholderText={`Enter due date`}
                onFocus={resetError}
                selected={filesDueDate}
                onChange={(date) => {
                  setFilesDueDate(date);
                  setTimeout(() => {
                    resetError({
                      target: document.getElementById("files_due"),
                    });
                    validate({
                      preventDefault: () => {},
                      target: document.getElementById("files_due"),
                    });
                  }, 1);
                }}
                css={`
                  ${inputCss}
                `}
                css={`
                  ${inputCss}
                `}
                required
              />
            </Label>
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}></Row>

        <Row pb={[20, 20, 35]}>
          <Column width={[1]}>
            <Label for="project_type">
              <LabelSpan>Project type</LabelSpan>
            </Label>
            <Box width={[1, 1, 1 / 2]}>
              <Input
                width={[1, 1, 1 / 2]}
                as={`select`}
                id={`project_type`}
                name={`project_type`}
                fontSize={normal}
                onFocus={resetError}
                onChange={(e) => {
                  setProjectType(e.target.value);
                }}
                style={{ paddingRight: 10 }}
                req
              >
                {projectSelectOpts.map(({ label, value }) => (
                  <option
                    key={`intakeform-${value}`}
                    value={value}
                    style={{ color: `black`, height: 30 }}
                  >
                    {label}
                  </option>
                ))}
              </Input>
            </Box>
            <Box width={[1, 1, 3 / 4]}>
              <InfoText
                theme={theme}
                dangerouslySetInnerHTML={{
                  __html: `${projectSelectInfo[projectType]} ${
                    projectType === `refresh` || projectType === `revise`
                      ? `${projectSelectInfo.noteRefreshRevise}`
                      : projectType === `mms`
                      ? `${projectSelectInfo.noteMms}`
                      : ``
                  }`,
                }}
              />
            </Box>
          </Column>
        </Row>
        <Row pb={[20, 20, 35]}>
          <Column width={[1, 1, 1 / 2]}>
            <Label for="language" mb={[20, 20, 30]}>
              <LabelSpan>Language</LabelSpan>
            </Label>
            {languages.map(({ label, value }) => (
              <CheckBox
                id={`language`}
                name={`language`}
                req
                onFocus={resetError}
                onBlur={validate}
                value={value}
                label={label}
              />
            ))}
          </Column>
          <Column width={[1, 1, 1 / 2]}>
            <Label for="comms_channel" mb={[20, 20, 30]}>
              <LabelSpan>Comms Channel</LabelSpan>
            </Label>
            {commsChannelTypes.map(({ label, value }) => {
              return (
                <>
                  <CheckBox
                    id={`comms_channel`}
                    name={`comms_channel`}
                    req
                    onFocus={resetError}
                    value={value}
                    label={label}
                    onChange={(e) => {
                      if (value === `other`) {
                        setShowCommsChannelOther(e.target.checked);
                      }
                    }}
                  />
                </>
              );
            })}
            <>
              <Label for="comms_channel_other">
                <VisuallyHidden>
                  <LabelSpan>Other Comms Channel</LabelSpan>
                </VisuallyHidden>
              </Label>
              <Textarea
                id={"comms_channel_other"}
                name={`comms_channel_other`}
                placeholder={`Enter comms channel`}
                onFocus={resetError}
                onBlur={validate}
                req={showCommsChannelOther}
                rows={1}
                style={{
                  minHeight: 0,
                  height: showCommsChannelOther ? `auto` : 0,
                  visibility: showCommsChannelOther ? `visible` : `hidden`,
                }}
              />
            </>
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1, 1 / 2]}>
            <Label for="brand" mb={[5, 10]}>
              <LabelSpan>Brand</LabelSpan>
            </Label>
            <Input
              as={`select`}
              id={`brand`}
              name={`brand`}
              fontSize={normal}
              onFocus={resetError}
              onChange={(e) => {
                setShowBrandOther(e.target.value === `other`);
              }}
              style={{ paddingRight: 10 }}
              req
            >
              {brandTypes.map(({ label, value }) => (
                <option
                  key={`brand-${value}`}
                  value={value}
                  style={{ color: `black`, height: 30 }}
                >
                  {label}
                </option>
              ))}
            </Input>
            <>
              <Label for="brand_other" mt={[5, 10]}>
                <VisuallyHidden>
                  <LabelSpan>Other Brand</LabelSpan>
                </VisuallyHidden>
              </Label>
              <Textarea
                id={"brand_other"}
                name={`brand_other`}
                placeholder={`Enter brand`}
                onFocus={resetError}
                onBlur={validate}
                req={showBrandOther}
                rows={1}
                style={{
                  minHeight: 0,
                  height: showBrandOther ? `auto` : 0,
                  visibility: showBrandOther ? `visible` : `hidden`,
                }}
              />
            </>
          </Column>
          <Column width={[1, 1, 1 / 2]}>
            <Label for="number_of_creative_versions">
              <LabelSpan>Number of creative versions</LabelSpan>

              <Input
                width={[1 / 2, 1 / 2, 1 / 3]}
                id={"number_of_creative_versions"}
                name={`number_of_creative_versions`}
                as={`input`}
                type={`number`}
                min={1}
                step={1}
                fontSize={normal}
                onFocus={resetError}
                req
                onBlur={validate}
                style={{ maxWidth: 100 }}
              />
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `How many different types of creative variations are needed in each language?<br/><strong>Note:</strong> this typically includes different versions of copy, colours or image selections. Languages do not drive version number. `,
              }}
            />
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1]}>
            <Label for="project_background">
              <LabelSpan>Project Background</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `In a few phrases, what needs to be done and why? What context would be helpful to know when developing the required messaging for consumers?`,
              }}
            />
            <Textarea
              id={"project_background"}
              name={`project_background`}
              placeholder={`Enter project background`}
              onFocus={resetError}
              onBlur={validate}
              req
            />
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1]}>
            <Label for="primary_message">
              <LabelSpan>Primary Message</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `What is the most important thing we need to communicate with this asset? Why is the consumer getting this communication from us?<br/><strong>ex. Exclusive offers, service updates, promoting OEM devices, etc.</strong>`,
              }}
            />
            <Textarea
              id={"primary_message"}
              name={`primary_message`}
              placeholder={`Enter primary message`}
              onFocus={resetError}
              onBlur={validate}
              req
            />
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1]}>
            <Label for="primary_cta">
              <LabelSpan>Primary CTA</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `What action do we want consumers to take with our primary message, and where will the CTA drive?<br/><strong>ex. Shop plans</strong>`,
              }}
            />
            <Textarea
              id={"primary_cta"}
              name={`primary_cta`}
              placeholder={`Enter primary CTA`}
              onFocus={resetError}
              onBlur={validate}
              req
            />
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1]}>
            <Label for="support_messages">
              <LabelSpan>Support Message(s)</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `If needed, this should help reinforce the primary message/offer.<br/><strong>ex. RTBs, discounts when adding a line/roaming, plan add-ons, etc.</strong>`,
              }}
            />
            <Textarea
              id={"support_messages"}
              name={`support_messages`}
              placeholder={`Enter support message(s)`}
              onFocus={resetError}
              onBlur={validate}
              req
            />
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1]}>
            <Label for="support_ctas">
              <LabelSpan>Support CTA(s)</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `If there are supporting messages, what are each asking consumers to do, and where will they drive?<br/><strong>ex. Learn more</strong>`,
              }}
            />
            <Textarea
              id={"support_ctas"}
              name={`support_ctas`}
              placeholder={`Enter support CTA(s)`}
              onFocus={resetError}
              onBlur={validate}
              req
            />
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1]}>
            <Label for="past_learnings">
              <LabelSpan>Past Learnings</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `Are there any pre-existing learnings from previous projects that are relevant to this project?<br/><strong>i.e. Partner review timelines, legal restrictions, etc.</strong>`,
              }}
            />
            <Textarea
              id={"past_learnings"}
              name={`past_learnings`}
              placeholder={`Enter past learnings & benchmarks info`}
              onFocus={resetError}
              onBlur={validate}
              req
            />
          </Column>
        </Row>
        <Row pb={[10, 10, 35]} jusifyContent={`space-between`}>
          <Column width={[1, 1]}>
            <Label for="final_thoughts">
              <LabelSpan>Final Thoughts</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `Are there any other pieces of information or reference information that are critical to the project that haven’t yet been shared in this document?`,
              }}
            />
            <Textarea
              id={"final_thoughts"}
              name={`final_thoughts`}
              placeholder={`Enter final thoughts`}
              onFocus={resetError}
              onBlur={validate}
              req
            />
          </Column>
        </Row>
        <Row pt={[20, 20]} ml={0}>
          <Column width={[1, 1, `50%`]}>
            <Label for="file">
              <LabelSpan>Project files</LabelSpan>
            </Label>
            <InfoText
              mb={20}
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: `Please upload any files relevant to this project here. You may select multiple files to upload by holding the <strong>CTRL (Mac:COMMAND)</strong> key while selecting files from your computer. <strong>Maximum upload file size is 5MB.</strong>`,
              }}
            />
            <StyledFileUpload
              color={theme.colors.white}
              fontFamily={theme.fonts.small}
              fontSize={[24, 30]}
              fontWeight={`bold`}
            >
              <input
                ref={fileUploadRef}
                id={"file"}
                type="file"
                name="file"
                multiple={true}
                onChange={uploadChange}
                required={projectType !== `new`}
              />
              <label htmlFor="file">Upload files</label>
              <figure
                style={{
                  position: `absolute`,
                  overflow: `hidden`,
                  top: 0,
                  right: `-1em`,
                  width: `0.5em`,
                  height: `0.5em`,
                }}
              >
                <ButtonArrow
                  fill={theme.colors.white}
                  width={`0.5em`}
                  height={`0.5em`}
                />
              </figure>
              {!fileValue && projectType !== `new` && (
                <Text
                  as={`span`}
                  fontFamily={theme.fonts.body}
                  fontSize={normal}
                  color={theme.colors.red}
                  style={{
                    width: 430,
                    display: `block`,
                    marginTop: 10,
                    whiteSpace: `nowrap`,
                  }}
                >
                  *(mandatory for refresh/revise requests)
                </Text>
              )}
              {fileValue && (
                <Button
                  bg={theme.colors.red}
                  ml={10}
                  fontFamily={theme.fonts.body}
                  fontSize={normal}
                  onClick={cancelFileUpload}
                >
                  Cancel
                </Button>
              )}
              {fileValue && (
                <Text
                  as={`span`}
                  fontFamily={theme.fonts.body}
                  fontSize={normal}
                  style={{ marginTop: 8, whiteSpace: `nowrap` }}
                >
                  {fileValue}
                </Text>
              )}
            </StyledFileUpload>
          </Column>
        </Row>
        <TextButtonWithArrow
          color={valid ? theme.colors.red : `rgba(255, 255, 255, 0.25)`}
          fontSize={[24, 30]}
          px={0}
          mt={[30, 40, 50]}
          as={`button`}
          label={`Submit`}
          type="submit"
          form="koodo-basecomms-intake"
          onClick={(e) => {
            e.preventDefault();
            // console.log("submitting form from button...");
            setIsSubmitted(true);
            return false;
          }}
          style={{
            cursor: valid ? `pointer` : `default`,
            willChange: `color`,
            transition: `color 0.2s ease-out`,
          }}
        />
      </fieldset>
      <div
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
          zIndex: 10,
          display: enabled ? `none` : `block`,
        }}
      />
    </PosedForm>
  );
}

export default withTheme(IntakeForm);

import * as easings from "d3-ease";

import { Container, Section } from "components/layout";
import React, { PureComponent } from "react";
import { biggest, normal, smallHeading } from "components/typography/sizes";
import styled, { withTheme } from "styled-components";

import GlobalEmitter from "utils/GlobalEmitter";
import { Heading } from "rebass";
import { PoseableHeading } from "components/poseable";
import PropTypes from "prop-types";
import TemplateWrapper from "components/TemplateWrapper";
import events from "utils/events";
import posed from "react-pose";

const Piece = posed.div({
  exit: {
    opacity: 0,
    x: 50,
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: ({ lineIndex }) => ({
      type: "tween",
      ease: easings.easeSinOut,
      delay: lineIndex * 100 + 250,
      duration: 400,
    }),
  },
});

const PosedHeading = styled(
  posed(PoseableHeading)({
    exit: {
      opacity: 0,
    },
    enter: { beforeChildren: true, opacity: 1, transition: { duration: 1 } },
  })
)`
  opacity: 0;
`;

class PageHeading extends PureComponent {
  static propTypes = {
    theme: PropTypes.object,
    heading: PropTypes.string.isRequired,
    preHeading: PropTypes.string,
    extra: PropTypes.node,
    containerProps: PropTypes.object,
    headingProps: PropTypes.object,
    ready: PropTypes.bool,
    withoutSection: PropTypes.bool,
    standalone: PropTypes.bool,
    style: PropTypes.object,
  };

  static defaultProps = {
    heading: "",
    ready: false,
    extra: null,
    standalone: false,
    withoutSection: false,
  };

  numPieces;
  pieces = [];
  offsets = [];
  yVals = [];

  calculate() {
    this.yVals = this.pieces.map(() => 0);
    this.offsets = this.pieces.map((piece) => piece.offsetTop);
    this.bottomOffset = this.offsets[this.offsets.lenght - 1] + 200;
  }

  onResize = () => {
    this.calculate();
  };

  componentDidMount() {
    this.numPieces = this.pieces.length;
    this.calculate();
    GlobalEmitter.on(events.resize, this.onResize);
    window.requestAnimationFrame(this.onAnimationFrame);
  }

  componentWillUnmount() {
    GlobalEmitter.off(events.resize, this.onResize);
    window.cancelAnimationFrame(this.onAnimationFrame);
  }

  onAnimationFrame = () => {
    let piece;
    let top;
    let ty;
    let zeroed =
      window.__smoothScrollY === 0 || TemplateWrapper.IS_TRANSITIONING;
    if (
      TemplateWrapper.IS_TRANSITIONING ||
      window.__smoothScrollY >= this.bottomOffset
    ) {
      window.requestAnimationFrame(this.onAnimationFrame);
      return;
    }
    for (let i = 0; i < this.numPieces; i++) {
      piece = this.pieces[i];
      if (!piece) {
        continue;
      }
      if (zeroed) {
        ty = 0;
      } else {
        top = piece.offsetTop - window.__smoothScrollY - this.offsets[0];
        ty = top * 0.075;
        this.yVals[i] = Math.min(this.yVals[i], 0);
      }
      if (Math.abs(this.yVals[i] - ty) < 0.5) {
        this.yVals[i] = ty;
      } else {
        this.yVals[i] += (ty - this.yVals[i]) / 10;
      }
      piece.style.transform = `translate3d(0, ${this.yVals[i]}px, 0)`;
    }
    window.requestAnimationFrame(this.onAnimationFrame);
  };

  renderHeading(str) {
    let hasRendered = this.hasRendered === true;
    if (!this.hasRendered) {
      this.hasRendered = true;
    }
    const ready = this.props.ready;
    const aStr = str.split("\n");
    return (
      <React.Fragment>
        {aStr.map((piece, idx) => (
          <div
            key={`heading-${str}-${idx}`}
            ref={(r) => (this.pieces[idx] = r)}
          >
            <Piece
              lineIndex={idx}
              initialPose={hasRendered ? "enter" : "exit"}
              pose={ready ? "enter" : "exit"}
              style={{
                display: `inline-block`,
              }}
            >
              {piece}
            </Piece>
            {idx === aStr.length - 1 ? null : <br />}
          </div>
        ))}
      </React.Fragment>
    );
  }

  render() {
    const {
      theme,
      heading,
      extra = null,
      color = null,
      extraDisplay = `after`,
      preHeading,
      containerProps,
      headingProps,
      preHeadingProps,
      ready,
      withoutSection,
      standalone = false,
      style,
      startAnimation,
      endAnimation,
      ...rest
    } = this.props;

    return standalone ? (
      <React.Fragment>
        <Heading
          is="h1"
          m={0}
          p={0}
          color={color || theme.fgColor}
          fontFamily="Druk"
          fontSize={biggest}
          lineHeight={`0.9em`}
          style={style}
          {...headingProps}
        >
          {this.renderHeading(heading)}
        </Heading>
      </React.Fragment>
    ) : (
      <Section style={style} {...rest}>
        <Container {...containerProps}>
          {extraDisplay === `before` ? extra : null}
          {preHeading && (
            <PosedHeading
              initialPose={"exit"}
              pose={ready ? "enter" : "exit"}
              is="h2"
              fontFamily={`Calibre`}
              fontSize={normal}
              pb={[`6px`, `10px`]}
              {...preHeadingProps}
            >
              {preHeading}
            </PosedHeading>
          )}
          <PosedHeading
            is="h1"
            m={0}
            pt={preHeading ? 0 : [50, 100]}
            color={color || theme.fgColor}
            fontFamily="Druk"
            fontSize={biggest}
            lineHeight={`0.9em`}
            initialPose={"exit"}
            pose={ready ? "enter" : "exit"}
            {...headingProps}
          >
            {this.renderHeading(heading)}
          </PosedHeading>
          {extraDisplay === `after` ? extra : null}
        </Container>
      </Section>
    );
  }
}

export default withTheme(PageHeading);

import BaseTemplate from "templates/base";
import BigPageLink from "components/BigPageLink";
import Footer from "components/Footer";
import FormBlock from "components/intake/FormBlock";
import { HelmetDatoCms } from "gatsby-source-datocms";
import PageHeading from "components/PageHeading";
import React from "react";
import TextBlock from "components/blocks/TextBlock";
import cssForBreakpoints from "utils/cssForBreakpoints";
import { graphql } from "gatsby";
import { normal } from "components/typography/sizes";
import { withTheme } from "styled-components";

const renderBlock = (idx, block, theme) => {
  const apiKey = block && block.model ? block.model.apiKey : null;
  let Cmp = null;
  let extras = {};

  switch (apiKey) {
    case "intake_form":
      Cmp = FormBlock;
      extras = {
        contentProps: {
          fontSize: normal,
          fontFamily: "Larish",
          fontWeight: "semibold",
          size: "small",
        },
      };
      break;
    case "text_block":
      Cmp = TextBlock;
      extras = {
        sectionProps: { pb: [0, 0, 0, 0, 0] },
        contentProps: {
          css: `
          & ol {
            display:block;
            list-style: none; 
            margin-left:17px;
            counter-reset: list-counter;
          }

          & ol > li::before {content: counter(list-counter) "."; color: ${
            theme.colors.red
          };
            display: inline-block; width: 1em;
            font-weight:bold;
            margin-left: -1em
          }
         
          & ol > li{counter-increment: list-counter}
          
          & ol ul{
            display:block;
            margin-bottom:25px !important;
          }
          
          & ul {
            list-style: disc inside;
          }

          & ul li ul{
            list-style: square inside;
            margin-left:17px;
          }

          & ul li::before {
            display:none;
          }
          
          & .red, & strong {
            color:${theme.colors.red}
          }

          & table{
            width:100%;
            background:${theme.colors.blackRevealColor};
            color:white;
            margin-bottom:30px;
          }

          & table tr:nth-child(even){
            background:${theme.colors.blackLineColor};
          }
          
          & table td{
            padding:16px 12px;
          }

          & h2{
            margin-top:20px;
          }

           & h3{
            ${cssForBreakpoints("font-size", [18, 22, 24, 28])}
          }

          & a {color:${theme.colors.red}}
          
          & .bordered{
            padding: 30px 20px 15px;
            margin: 40px 0;
            border: 1px solid ${theme.colors.red};
          }
          `,
        },
      };
      break;
    case "big_page_link":
      Cmp = BigPageLink;
      extras = { big: true };
      break;
    default:
      break;
  }
  if (idx === 0) {
    extras.sectionProps = { ...extras.sectionProps, autoShow: true };
  }
  if (Cmp) {
    return <Cmp theme={theme} key={block.id} {...block} {...extras} />;
  }

  return null;
};

class IntakeTemplate extends BaseTemplate {
  get usePageReadyTimeout() {
    return false;
  }

  componentDidMount() {
    this.pageReadyTimeout = setTimeout(this.onPageReady, 1500);
  }

  componentWillUnmount() {
    clearTimeout(this.pageReadyTimeout);
  }

  render() {
    const { ready } = this.state;
    const { data, theme } = this.props;
    const { page } = data;
    const { heading, seoMetaTags, body } = page;
    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <link rel="preload" as="image" href="/loader-pink.gif" />
        </HelmetDatoCms>
        <PageHeading
          theme={theme}
          heading={heading}
          ready={ready}
          headingProps={{ pt: `2vh` }}
        />
        {body && body.map((block, idx) => renderBlock(idx, block, theme))}
        <Footer theme={theme} globals={this.props.pageContext.globals} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    page: datoCmsBasecommsIntake {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heading
      body {
        ... on DatoCmsIntakeForm {
          id
          model {
            apiKey
          }
          asideHeading
          introNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsTextBlock {
          id
          model {
            apiKey
          }
          showAside
          asidePrefix
          asideHeading
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsBigPageLink {
          id
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
          linkLabel
          link
        }
      }
    }
  }
`;

export default withTheme(IntakeTemplate);

import "@reach/dialog/styles.css";

import * as easings from "d3-ease";

import { Container, PosedSection, Row } from "components/layout";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import {
  PoseableBox,
  PoseableButton,
  PoseableColumn,
} from "components/poseable";
import React, { useState } from "react";

import IntakeForm from "./IntakeForm";
import SectionAside from "components/typography/SectionAside";
import { Text } from "rebass";
import { fadeUp } from "components/poses";
import posed from "react-pose";

const PosedColumn = posed(PoseableColumn)(fadeUp);

const PosedMessageContainer = posed(PoseableBox)({
  inactive: { opacity: 0, visibility: `hidden` },
  active: {
    opacity: 1,
    applyAtStart: { visibility: `visible` },
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
});

const boxProps = {
  inactive: {
    opacity: 0,
    x: `-50%`,
    y: `calc(-50% + 5px)`,
    visibility: `hidden`,
  },
  active: {
    opacity: 1,
    x: `-50%`,
    y: `-50%`,
    applyAtStart: { visibility: `visible` },
    transition: ({ visibledelay = 0 }) => ({
      type: "tween",
      duration: 300,
      delay: visibledelay,
      ease: easings.easeSinOut,
    }),
  },
  done: {
    opacity: 0,
    x: `-50%`,
    y: `calc(-50% - 10px)`,
    applyAtEnd: { visibility: `hidden` },
    transition: {
      type: "tween",
      duration: 300,
      ease: easings.easeSinIn,
    },
  },
};

const PosedSending = posed(PoseableBox)(boxProps);
const PosedThanks = posed(PoseableBox)(boxProps);

function FormBlock({
  active,
  theme,
  asideHeading,
  introNode,
  sectionProps = {},
  contentProps = {},
}) {
  const [sending, setSending] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [formEnabled, setFormEnabled] = useState(false);

  return (
    <>
      <Container mt={[10, 15]} mb={[30, 40, 50, 60]}>
        <Row>
          <PosedColumn
            width={[1, 1, 1, 11 / 12]}
            ml={[0, 0, 0, `${(1 / 12) * 100}%`]}
          >
            <PoseableButton
              p={[12, 15, 18]}
              fontSize={[18, 20, 22]}
              fontFamily={`Calibre`}
              fontWeight={`bold`}
              bg={theme.colors.red}
              ml={[0, 0, 1 / 12]}
              style={{ cursor: `pointer` }}
              onClick={() => {
                setFormEnabled(true);
              }}
            >
              I have read the above process guide
            </PoseableButton>
          </PosedColumn>
        </Row>
      </Container>
      <PosedSection pb={theme.gap.small} {...sectionProps}>
        <Container>
          <Row>
            <SectionAside label={asideHeading} theme={theme} />
            <PosedColumn width={[1, 1, 1, 11 / 12]}>
              <React.Fragment>
                <IntakeForm
                  introNode={introNode}
                  sending={sending}
                  setSending={setSending}
                  setThanks={setThanks}
                  enabled={formEnabled}
                />
                {(sending || thanks) && (
                  <DialogOverlay
                    isOpen={true}
                    style={{
                      zIndex: 9999,
                      opacity: 1,
                      willChange: `opacity`,
                      overflow: `hidden`,
                      transition: `opacity 0.3s ease-out`,
                    }}
                  >
                    <DialogContent style={{ background: `transparent` }}>
                      <PosedSending
                        withParent={false}
                        initialPose={`inactive`}
                        pose={sending ? `active` : thanks ? `done` : `inactive`}
                        width={[`90%`, `75%`, `50%`]}
                        p={[20, 30, 40]}
                        bg={theme.colors.red}
                        style={{
                          position: `absolute`,
                          top: `50%`,
                          left: `50%`,
                          transform: `translate3d(-50%, 0, 0)`,
                        }}
                      >
                        <Text
                          color={theme.colors.black}
                          fontSize={[24, 32, 36]}
                          textAlign={`center`}
                          mb={0}
                        >
                          Sending...
                        </Text>
                      </PosedSending>
                      <PosedThanks
                        withParent={false}
                        initialPose={`inactive`}
                        pose={thanks ? `active` : `inactive`}
                        width={[`90%`, `75%`, `50%`]}
                        p={[20, 30, 40]}
                        bg={theme.colors.red}
                        visibledelay={1000}
                        style={{
                          position: `absolute`,
                          top: `50%`,
                          left: `50%`,
                          transform: `translate3d(-50%, 0, 0)`,
                        }}
                      >
                        <Text
                          color={theme.colors.black}
                          fontSize={[24, 32, 36, 42]}
                          textAlign={`center`}
                          mb={[10, 20, 25]}
                        >
                          Thanks!
                        </Text>
                        <Text
                          color={theme.colors.black}
                          fontFamily={theme.fonts.small}
                          fontSize={[18, 20]}
                          textAlign={`center`}
                          dangerouslySetInnerHTML={{
                            __html: `Your project intake form has been submitted.<br/>Someone from CJ will contact you in the next 24 hours.<br/>You can now close this tab.`,
                          }}
                        />
                      </PosedThanks>
                    </DialogContent>
                  </DialogOverlay>
                )}
              </React.Fragment>
            </PosedColumn>
          </Row>
        </Container>
      </PosedSection>
    </>
  );
}

export default FormBlock;
